import React, { useState } from 'react';

const SurveySection = ({ icon, title, subtitle, text, buttonText, buttonLink }) => {
  const [isActive, setIsActive] = useState(false);

  const handleToggle = () => {
    setIsActive(!isActive);
  };

  return (
    <div className="accordion">
      <div className="accordion-item">
        <div className="accordion-header" onClick={handleToggle}>
          <h2>{title}</h2>
          <span className={`accordion-icon ${isActive ? 'active' : ''}`}></span>
        </div>
        {isActive && (
          <div className="accordion-content">
            
            <h3>{subtitle}</h3>
            
            <p dangerouslySetInnerHTML={{ __html: text }}></p>

            <a href={buttonLink} className="survey-button">{buttonText}</a> 
          </div>
        )}
      </div>
    </div>
  );
};

export default SurveySection;
