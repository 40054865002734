import React from 'react';

const SocialMediaLinks = ({ blok }) => {
  if (!blok || blok.length === 0) {
    return null; // oder eine alternative Darstellung, falls keine Links vorhanden sind
  }

  return (
    <div id="social-media-links">
      <ul className="list-unstyled list-row list-group-horizontal">
        {blok.map((link, index) => (
          <li className="list-group-item" key={index}>
            <a href={link.url.cached_url} target="_blank" rel="noreferrer" aria-label={link.ariaLabel}>
              <img src={link.icon.filename} alt={link.icon.alt} />
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default SocialMediaLinks;




