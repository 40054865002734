import React from 'react';

const SurveyThankSection = ({ title, text }) => {
  return (

<div className="accordion">
      <div className="accordion-item">
        <div className="accordion-header">
        <h2 dangerouslySetInnerHTML={{ __html: title }} />
        </div>
          <div className="accordion-content-2">
            <p dangerouslySetInnerHTML={{ __html: text }}></p>
            </div>
            </div>
            </div>
           
            
    
  );
};

export default SurveyThankSection;
