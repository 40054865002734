import React from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';

const Header = ({ logo }) => {
  const navigate = useNavigate();
  const { lang } = useParams();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const di = queryParams.get('di');

  const handleLanguageChange = (newLang) => {
    const newQueryParams = new URLSearchParams(location.search);
    newQueryParams.set('di', di); // Beibehalten des 'di'-Parameters
    navigate(`/${newLang}?${newQueryParams.toString()}`);
  };

  return (
    <header className="header">
      <img src={logo} alt="Blaguss Logo" className="logo" />
      <div className="language-switcher">
        <button
          onClick={() => handleLanguageChange('de')}
          disabled={lang === 'de'}
        >
          DE
        </button>
        <button
          onClick={() => handleLanguageChange('en')}
          disabled={lang === 'en'}
        >
          EN
        </button>
      </div>
    </header>
  );
};

export default Header;
