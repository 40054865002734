import React, { useState } from 'react';

const Accordion = ({ title, items, showIcon = true, isList = false, options }) => {
  const [isActive, setIsActive] = useState(false);

  const handleToggle = () => {
    setIsActive(!isActive);
  };

  return (
    
    <div className={`accordion ${isActive ? 'active' : ''}`}>
      <div className={`accordion-item ${isActive ? 'active' : ''}`}>
        <div className="accordion-header" onClick={handleToggle}>
          <h2 dangerouslySetInnerHTML={{ __html: title }} />
        </div>
        {isActive && (
          <div className="accordion-content">
         {isList ? (
  <>
    {items.length > 0 && (
      <div className="item-header">
        <h3 dangerouslySetInnerHTML={{ __html: items[0].title }}
        />
      </div>
    )}
    <ul>
      {items.slice(1).map((item, index) => (
        <li key={index}>{item.title}</li>
      ))}
    </ul>
  </>
            ) : (
              items
              .filter(item => {
                const markerOptions = parseInt(item.marker, 10);
              
                // Wenn kein Marker gesetzt ist, zeige das Item standardmäßig an
                if (!markerOptions) {
                  return true;
                }
              
                // Wenn markerOptions 16 oder 32 ist, zeige das Item nur, wenn es explizit in den options enthalten ist
                if (markerOptions === 16 || markerOptions === 32) {
                  return (options & markerOptions) !== 0;
                }
              
                // Bestimme, ob das Item angezeigt werden soll basierend auf den Optionen und dem Marker
                const shouldHideItem = (options & markerOptions) !== 0;
              
                return !shouldHideItem;
              })
              
              
                .map((item, index) => (
                  <div key={index} className={`accordion-subitem ${isActive ? 'active' : ''}`}>
                    <div className="item-header">
                      <h3 dangerouslySetInnerHTML={{ __html: item.title }} />
                      {showIcon && item.icon && (
                        <img src={item.icon.filename} alt={item.title} className="icon" />
                      )}
                    </div>
                    <p dangerouslySetInnerHTML={{ __html: item.content }}></p>
                  </div>
                ))
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default Accordion;





