import StoryblokClient from 'storyblok-js-client';

const Storyblok = new StoryblokClient({
  accessToken: 'N4L1rtdqcVVIiQJeJGgbEQtt',
  cache: {
    clear: 'auto',
    type: 'memory'
  }
});

export default Storyblok;

