import React, { useEffect, useState } from 'react';
import { HashRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import LanguageWrapper from './components/LanguageWrapper';
import CookieBar, { areCookiesAccepted } from './components/CookieBar';
import './styles.css';

function App() {
  const [cookiesAccepted, setCookiesAccepted] = useState(areCookiesAccepted());

  const handleCookieAccept = () => {
    setCookiesAccepted(true);
  };

  useEffect(() => {
    var _paq = window._paq = window._paq || [];

    if (cookiesAccepted) {
      _paq.push(['trackPageView']);
      _paq.push(['enableLinkTracking']);
    } else {
      _paq.push(['disableCookies']);
      _paq.push(['trackPageView']);
      _paq.push(['enableLinkTracking']);
    }

    (function() {
      var u="//analytics.blaguss.eu/";
      _paq.push(['setTrackerUrl', u + 'matomo.php']);
      _paq.push(['setSiteId', '2']);
      var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
      g.async=true; g.src=u+'matomo.js'; s.parentNode.insertBefore(g,s);
    })();

  }, [cookiesAccepted]);

  return (
    <HelmetProvider>
      <Router>
        <Routes>
          <Route path="/:lang" element={<LanguageWrapper cookiesAccepted={cookiesAccepted} handleCookieAccept={handleCookieAccept} />} />
          <Route path="/" element={<LanguageRedirect />} />
        </Routes>
      </Router>
    </HelmetProvider>
  );
}

function LanguageRedirect() {
  return <Navigate to="/en" replace />;
}

export default App;
