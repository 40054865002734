import React from 'react';
import videoSrc from './onboard_video_v2.webm';

const VideoSection = () => (
  <div className="video-wrapper">
    <video controls width="100%" autoPlay muted playsInline>
      <source src={videoSrc} type="video/webm" />
      Dein Browser unterstützt das Video-Tag nicht.
    </video>
  </div>
);

export default VideoSection;


