import React, { useState, useEffect } from 'react';

const CookieBar = ({ text, buttonText, buttonTextD, onAccept, onDecline }) => {
  const [showCookieBar, setShowCookieBar] = useState(false);

  useEffect(() => {
    const acceptedTimestamp = localStorage.getItem('cookiesAcceptedTimestamp');
    const cookieBarShown = localStorage.getItem('cookieBarShown');
    const currentTime = new Date().getTime();

    // Überprüfen, ob die Zustimmung älter als 1 Minute (60000 ms) ist und ob die Cookie-Bar bereits gezeigt wurde
    if (!cookieBarShown && (!acceptedTimestamp || currentTime - acceptedTimestamp > 60000)) {
      setShowCookieBar(true);
      localStorage.setItem('cookieBarShown', 'true'); // Markiere, dass die Cookie-Bar gezeigt wurde
    }
  }, []);

  const handleAccept = () => {
    localStorage.setItem('cookiesAccepted', 'true');
    localStorage.setItem('cookiesAcceptedTimestamp', new Date().getTime());
    setShowCookieBar(false);
    if (onAccept) {
      onAccept(); // Rufe die onAccept-Funktion auf, um den Zustand in der App zu aktualisieren
    }
  };

  const handleDecline = () => {
    localStorage.removeItem('cookiesAccepted');
    localStorage.removeItem('cookiesAcceptedTimestamp');
    setShowCookieBar(false);
    if (onDecline) {
      onDecline(); // Rufe die onDecline-Funktion auf, falls erforderlich
    }
  };

  return (
    <>
      {showCookieBar && (
        <div className="cookie-bar-1">
          <p dangerouslySetInnerHTML={{ __html: text }} />
          <div className="cookie-bar-buttons">
            <button onClick={handleAccept}>{buttonText}</button>
            <button onClick={handleDecline}>{buttonTextD}</button>
          </div>
        </div>
      )}
    </>
  );
};

export const areCookiesAccepted = () => {
  const acceptedTimestamp = localStorage.getItem('cookiesAcceptedTimestamp');
  const currentTime = new Date().getTime();

  return (
    localStorage.getItem('cookiesAccepted') === 'true' &&
    acceptedTimestamp &&
    currentTime - acceptedTimestamp <= 60000 // Überprüfen, ob die Zustimmung noch gültig ist
  );
};

export default CookieBar;
