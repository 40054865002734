import React from 'react';
import { useParams } from 'react-router-dom';
import LandingPage from './LandingPage';

function LanguageWrapper({ cookiesAccepted, handleCookieAccept }) {
  const { lang } = useParams();
  const selectedLanguage = lang || 'en';

  return <LandingPage language={selectedLanguage} cookiesAccepted={cookiesAccepted} handleCookieAccept={handleCookieAccept} />;
}

export default LanguageWrapper;
