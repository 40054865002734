import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import Storyblok from '../StoryblokService';
import Header from './Header';
import VideoSection from './VideoSection';
import SectionTitle from './SectionTitle';
import SectionTitleText from './SectionTitleText';
import SectionSubtitle from './SectionSubtitle';
import Services from './Services';
import Safety from './Safety';
import SurveySection from './SurveySection';
import SurveyThankSection from './SurveyThankSection';
import Footer from './Footer';
import SocialMediaLinks from './SocialMediaLinks';
import CookieBar from './CookieBar';
import { useLocation } from 'react-router-dom';

function LandingPage({ language, cookiesAccepted, handleCookieAccept }) {
  const [data, setData] = useState(null);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const diFull = queryParams.get('di');

  let di, options;

  if (diFull && diFull.length > 32) {
    di = diFull.slice(0, 32);
    const optionsFull = diFull.slice(-2);
    options = parseInt(optionsFull, 10).toString();
  } else {
    di = diFull;
    options = null;
  }

  const showThankSection = queryParams.get('survey') === 'TRUE';
  const firstThreeDigits = di ? di.slice(0, 3) : '';

  useEffect(() => {
    const story = language === 'en' ? 'onboard-webapp-en' : 'onboard-webapp';
    Storyblok.get(`cdn/stories/${story}`, {
      version: 'draft'
    })
      .then(response => {
        setData(response.data.story.content);
      })
      .catch(error => {
        console.error(error);
      });
  }, [language]);

  if (!data) {
    return <div>Loading...</div>;
  }

  const {
    logo,
    videoUrl,
    sectionTitle,
    sectionTitleText,
    sectionSubtitle,
    services,
    safety,
    surveyIcon,
    surveyTitle,
    surveySubTitle,
    surveyText,
    surveyButtonText,
    surveyButtonLink,
    footerText,
    safetyTitle,
    serviceTitle,
    surveyThankText,
    socialMedia,
    cookieText,
    cookieButtonText,
    cookieDeclineText,
  } = data;

  const surveyLinkWithHashtag = di ? `${surveyButtonLink}&hashtag=${di}` : surveyButtonLink;

  // Definiere die handleCookieDecline Funktion
  const handleCookieDecline = () => {
    localStorage.removeItem('cookiesAccepted');
    localStorage.removeItem('cookiesAcceptedTimestamp');
    window.location.reload(); // Seite neu laden, um die Änderungen zu übernehmen
  };

  return (
    <div className="App">
      <Helmet>
        <title>Blaguss Onboard</title>
      </Helmet>
      <Header logo={logo?.filename} />
      <main className="main-content">
        <VideoSection videoUrl={videoUrl} />
        <SectionTitle title={sectionTitle} />
        <SectionSubtitle title={sectionSubtitle} />
        <SectionTitleText title={sectionTitleText} />
        <Services title={serviceTitle} items={services} options={options} />
        <Safety title={safetyTitle} items={safety} />

        {cookiesAccepted && (
          <>
            {showThankSection ? (
              <SurveyThankSection title={surveyTitle} text={surveyThankText} />
            ) : (
              <SurveySection
                icon={surveyIcon?.filename}
                title={surveyTitle}
                subtitle={surveySubTitle}
                text={surveyText}
                buttonText={surveyButtonText}
                buttonLink={surveyLinkWithHashtag}
              />
            )}
          </>
        )}

        {socialMedia && <SocialMediaLinks blok={socialMedia} />}
        <Footer
  text={footerText}
  hashcode={firstThreeDigits}
  options={options}
  lang={language}
  cookiesAccepted={cookiesAccepted}
  onDeclineCookies={handleCookieDecline} 
  onAcceptCookies={handleCookieAccept}  // Funktion für Cookie-Akzeptanz
  buttonTextD={cookieDeclineText} 
  buttonTextE={cookieButtonText} 
/>

        <CookieBar 
          text={cookieText} 
          buttonText={cookieButtonText} 
          buttonTextD={cookieDeclineText} 
          onAccept={handleCookieAccept} 
          onDecline={handleCookieDecline} 
        />
      </main>
    </div>
  );
}

export default LandingPage;
