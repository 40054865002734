import React from 'react';
import Accordion from './Accordion';

const Safety = ({ title, items }) => {
  return (
    <Accordion title={title} items={items} showIcon={false} isList={true} />
  );
};

export default Safety;

