import React from 'react';
import Accordion from './Accordion';

const Services = ({ title, items, options }) => {
  return (
    <Accordion title={title} items={items} showIcon={true} isList={false} options={options} />
  );
};

export default Services;


